// COMPONENT [ APP > HEADER ] ##################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React, { Fragment, useState, useEffect } from 'react';
import propTypes from 'prop-types';

import URL from '../../../constants/urls';

import { MainNav, MobileNav } from '../navigation';
import Go from '../goto';

import { ReactComponent as BurgerIcon } from '../../../assets/images/icons/burger.svg';
import { ReactComponent as Logo } from '../../../assets/images/site/logo.svg';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function SiteHeader({ className }) {

  // 2.1. HOOKS ................................................................

  // 2.1.1. STATE

  const [mobileNav, setMobileNav] = useState(false);
  const [float, setFloat] = useState(true);
  

  // 2.1.1. END

  // 2.1.2. EFFECT

  useEffect(() => {
    
    isFloating(window.pageYOffset || document.documentElement.scrollTop);

  });

  // 2.1.2. END

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2.1. SCROLL POSITION

  function isFloating(scrollPos) {
    if(scrollPos > 50) {
      setFloat(true);
    } else {
      setFloat(false);
    }
  }

  if (typeof window !== `undefined`) {
    window.addEventListener('scroll', (event) => {
      isFloating(window.pageYOffset || document.documentElement.scrollTop);
    });
  }

  // 2.2.1. END

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (

    <Fragment>

      <header className={ `f-header  ${ className } ${ float ? 'f-header--floating' : '' }` }>

        <Go
          className="o-btn-icon o-btn-icon--large o-btn-icon--logo f-header__logo"
          to={ URL.site.homepage }
        >
          <Logo />
        </Go>

        <MainNav
          className="f-header__nav"
        />

        <button
          className="f-header__burger o-btn-icon o-btn-icon--burger"
          onClick={ () => setMobileNav(true) }
        >
          <BurgerIcon />
        </button>

      </header>

      <MobileNav
        className="f-header__nav-mobile"
        isOpen={ mobileNav }
        closeNav={ () => setMobileNav(false) }
      />

    </Fragment>

  );
  
  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

SiteHeader.propTypes = {
  className: propTypes.string,
};

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default SiteHeader;

// END OF FILE #################################################################