// CONSTANTS [ NAVIGATION ] ####################################################

import URL from '../constants/urls';

const Cta = {

  // 1. HEADER ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  header: [

    {
      label: "Company",
      url: URL.site.company,
      class: false
    },
    {
      label: "CRE Sponsors",
      url: URL.site.cre_sponsors,
      class: false
    },
    {
      label: "Family Offices",
      url: URL.site.family_offices,
      class: false
    },
    {
      label: "Affiliates",
      url: URL.site.affiliates,
      class: false
    },

  ],

  // 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // 2. FOOTER +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  footer: [

    {
      label: "Company",
      url: URL.site.company,
      class: false
    },
    {
      label: "CRE Sponsors",
      url: URL.site.cre_sponsors,
      class: false
    },
    {
      label: "Family Offices",
      url: URL.site.family_offices,
      class: false
    },
    {
      label: "Affiliates",
      url: URL.site.affiliates,
      class: false
    },

  ],

  // 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

};

export default Cta;

// END OF FILE #################################################################
