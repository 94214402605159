// COMPONENT [ APP > NAVIGATION > MAIN ] #######################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React, { useState } from 'react';
import propTypes from 'prop-types';

import NAV from '../../../constants/navigation';
import TAG from '../../../constants/tracking_tags';


import PopLink from '../poplink';
import Go from '../goto';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function MainNav({ className }) {

  // 2.1. HOOKS ................................................................

  const [buttonHovering, setButtonHovering] = useState(false);
  const [coords, setCoords] = useState(0);
  const [type, setType] = useState('site');

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2.1. BUTTON ENTER / EXITS

  const handle_button_enter = (e) => {
    let offset = e.target.offsetLeft + (e.target.clientWidth / 2);
    setCoords(offset);
    setButtonHovering(true);
    setType(e.target.dataset.type);

  }
  const handle_button_leave = (e) => {
    setButtonHovering(false);

  }

  // 2.2.1. END

  // 2.2.2. RENDER NAVIGATION
  const renderNav = data => (
    data.map( (link, index) => (
      <li key={ index }><Go className="f-nav__link" to={ link.url }>{ link.label }</Go></li>
    ))
  );

  // 2.2.1. END

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (
    <nav className={ `f-nav ${ className ? className : '' }` }>

      <ul>
        { renderNav(NAV.header) }
      </ul>

    </nav>
  );

  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

MainNav.propTypes = {
  className: propTypes.string,
};

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default MainNav;

// END OF FILE #################################################################
