// COMPONENT [ APP > COOKIE ] ##################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
//import propTypes from 'prop-types';

import URL from '../../../constants/urls';

import Go from '../goto';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function Cookie(props) {

  let date = new Date();
  date.setMonth(date.getMonth() + 2);

  const options = {
   path: '/',
   expires: date,
   domain: 'corporate.wealthmigrate.com',
   secure: false,
   sameSite: false,
  }


  // 2.1. HOOKS ................................................................

  const [show, setShow] = useState(true);
  const [cookies, setCookie] = useCookies(['corporate_wealthmigrate_com']);

  useEffect(() => {

    if (cookies.accept_cookies) {
      setShow(false); 
    }

  })

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  function handleClick() {
    setCookie('accept_cookies', true, options)
    setShow(false);
  }

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  if (show) {
    return (
      <aside className="f-cookies">

        <ul>
          <li>We use cookies to ensure you the best experience on our website.</li>
          <li><Go to={ URL.external.legal }>Learn more</Go></li>
          <li><button className="o-btn uhm-what" onClick={ () => handleClick() }>Got it</button></li>
        </ul>

      </aside>
    );
  }

  return null;

  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

Cookie.propTypes = {};

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default Cookie;

// END OF FILE #################################################################
