// COMPONENT [ APP > FOOTER ] ##################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import propTypes from 'prop-types';
//import { Go } from '@wealthmigrate/shared-assets';

import Go from '../goto';

import Nav from '../../../constants/navigation';

import { ReactComponent as Logo } from '../../../assets/images/site/logo.svg';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function Footer({ className }) {

  // 2.1. HOOKS ................................................................

  // 2.1.1. USESTATICQUERY

  const data = useStaticQuery(graphql`
  {
    prismic {
      contact_details(uid:"contact", lang: "en-us") {
        phone_number
        email_address
      }
    }
  }
  `);

  const {
    email_address,
    phone_number
  } = data.prismic.contact_details;

  // 2.1.1. END

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2.1. RENDER NAVIGATION

  const renderNav = data => (
    data.map(link => (
      <li><Go to={ link.url }>{ link.label }</Go></li>
    ))
  );

  // 2.2.1. END

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (
    <footer className={ `f-footer ${ className }` }>

      <figure className="f-footer__img">
        <Logo />
      </figure>

      <div className="f-footer__content">

        <nav className="f-footer__nav f-footer__nav--secondary">
          <h4><a href={ `tel:${ phone_number }`}>{ phone_number }</a> / <a href={ `mailto:${ email_address }`}>{ email_address }</a></h4>
          <ul>
            { renderNav(Nav.footer) } 
          </ul>
        </nav>

      </div>

      <div className="f-footer__bottom">

        <h6>Your information is safe and it belongs to you. | Copyright &copy; {  (new Date().getFullYear()) } Wealth Migrate. | All rights reserved.</h6>

      </div>

    </footer>
  );

  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPTYPES ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

Footer.propTypes = {
  className: propTypes.string,
}

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default Footer;

// END OF FILE #################################################################
