// COMPONENT [ LAYOUT > MAIN ] #################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


// 1.1. EXTERNAL DEPENDENCIES ..................................................

import React from 'react';
import propTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { CookiesProvider } from 'react-cookie';
import { PageTemplate } from '@wealthmigrate/shared-assets';

// 1.1. END ....................................................................

// 1.2. INTERNAL DEPENDENCIES ..................................................

// 1.2.1. COMPONENTS

import { SiteHeader } from '../app/headers';
import Footer from '../app/footer';
import Cookie from '../app/cookie';

import SEO from '../gatsbyjs/seo';

// 1.2.1. END

// 1.2. END ....................................................................

// 1.3. MEDIA ..................................................................

import '../../assets/scss/stylesheet.scss';

// 1.3. END ....................................................................

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function MainLayout({
  className,
  children,
  title,
  description,
  image,
  keywords,
  content 
}) {

  // 2.1. HOOKS ................................................................
  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................
  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (
    <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={
      data => {
        return (

          <CookiesProvider>
    
            <SEO
              title={ title }
              description={ description }
              keywords={ [
                `gatsby`, 
                `application`, 
                `react`] 
              }
              image={ image }
            />

            <div className={ `l-page ${ className ? className : '' }` }>
          
              <SiteHeader className="l-page__header" />
              
              <main className="l-page__body">
                { content && <PageTemplate content={ content } /> }
                { children && children }
              </main>

              <Footer className="l-page__footer" />

            </div>        
    
            <Cookie />
    
          </CookiesProvider>
    
        )
      }
    }
  />
  );
  
  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROP-TYPES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

MainLayout.propTypes = {
  className: propTypes.string,
  image: propTypes.string,
  children: propTypes.node,
  title: propTypes.string,
  description: propTypes.string,
  keywords: propTypes.array,
  data: propTypes.object,
}

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default MainLayout;

// END OF FILE #################################################################