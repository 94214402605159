// VIEW [ PAGE ] ###############################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React from 'react';
import { graphql } from 'gatsby';

import { MainLayout } from '../components/layouts';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function PageTemplate({ data, children }) {

  const {
    og_title,
    group,
    og_description,
    og_image,
    canvas_angle,
    canvas_tag,
    canvas_heading,
    canvas_description,
    canvas_background,
    body,   
  } = data.prismic.page;

  // 2.1. HOOKS ................................................................

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (
    <MainLayout
      title={ `${ og_title } ${ group ? `- ${ group }` : '' }` }
      description={ og_description }
      keywords={ [
        group,
        `Wealth Migrate`] 
      }
      content={ data.prismic.page }
    />
  );
  
  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 4. GRAPHQL QUERY ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const pageQuery = graphql`
  query pageQuery($uid: String!) {
    prismic {
      page(uid: $uid lang: "en-us") {
        class
        url
        og_title
        og_description
        og_image
        canvas_angle
        canvas_tag
        canvas_heading
        canvas_description
        canvas_background
        body {
          ...on PRISMIC_PageBodyDisplay_box {
            type
            primary {
              angle
              style
              background
              orientation
              content
              image
            }
          }
          ...on PRISMIC_PageBodyText_box {
            type
            primary {
              angle
              background
              heading
              description
            }
            fields {
              content
            }
          }
        }
      }
    }
  }
`;

// 4. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default PageTemplate;

// END OF FILE #################################################################