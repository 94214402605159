// COMPONENT [ APP > Link ] ####################################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'gatsby';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function Go(props) {

  // 2.1. HOOKS ................................................................
  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  if(props.to.includes('https://') || props.to.includes('http://')) {
    return (
      <a href={ props.to } target="_blank" rel="noopener noreferrer" className={ props.className } data-tag={ props.tag && props.tag }>
        { props.children }
      </a>
    );
  }

  return (
    <Link to={ props.to } className={ props.className } data-tag={ props.tag && props.tag }>
      { props.children}
    </Link>
  );
  
  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

Go.propTypes = {
  children: propTypes.node.isRequired,
  to: propTypes.string.isRequired,
  className: propTypes.string,
  tag: propTypes.string,
};

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default Go;

// END OF FILE #################################################################