// COMPONENT [ APP > NAVIGATION > MOBILE ] #####################################

// 1. DEPENDENCIES +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import React, { useState } from 'react';
import propTypes from 'prop-types';

import URL from '../../../constants/urls';
import Nav from '../../../constants/navigation';

import Go from '../goto';

import { ReactComponent as BackIcon } from '../../../assets/images/icons/angle-right.svg';

// 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 2. COMPONENT ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

function MobileNav({ className, isOpen, closeNav }) {

  // 2.1. HOOKS ................................................................

  const [type, setType] = useState('');

  // 2.1. END ..................................................................

  // 2.2. FUNCTIONS ............................................................

  // 2.2.2. BUTTON ENTER / EXITS

  const handleSub = (e) => {
    setType(e);
  }

  const handleClick = (e) => {
    setType(e);
  }

  // 2.2.2. END

  // 2.2. END ..................................................................

  // 2.3. RENDER ...............................................................

  return (
    <nav className={ `f-nav-mobile ${ isOpen ? 'f-nav-mobile--open' : '' } ${ className ? className : '' }` }>
      <button
        className="o-btn-icon o-btn-icon--burger"
        onClick={ () => closeNav() }
      >
        <BackIcon />
      </button>
      <ul className="f-nav-mobile__top">
        <li>
          <Go to={ URL.site.homepage }>
            Home
          </Go>
        </li>
        <li>
          <Go to={ URL.site.company }>
            Company
          </Go>
        </li>
        <li>
          <Go to={ URL.site.cre_sponsors }>
            CRE Sponsors
          </Go>
        </li>
        <li>
          <Go to={ URL.site.family_offices }>
            Family Offices
          </Go>
        </li>
        <li>
          <Go to={ URL.site.affiliates }>
            Affiliates
          </Go>
        </li>
      </ul>

    </nav>
  );

  // 2.3. END ..................................................................

};

// 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// 3. PROPS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

MobileNav.propTypes = {
  className: propTypes.string,
  isOpen: propTypes.bool,
  closeNav: propTypes.func,
};

// 3. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export default MobileNav;

// END OF FILE #################################################################
