// CONSTANTS [ URLS ] ##########################################################

const Routes = {

  // 1. EXTERNAL URLS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  external: {
    wealth_migrate: 'https://wealthmigrate.com',
    wealth_university: 'https://wealthuniversity.org',
    wealth_e: 'https://wealthe.io',
    legal: 'https://wealthmigrate.com/legal/legal-information'
  },

  // 1. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // 2. SITE URLS ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  site: {
    homepage: '/',
    company: '/company',
    cre_sponsors: '/cre-sponsors',
    affiliates: '/affiliates',
    family_offices: '/family-offices',
  },

  // 2. END ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

};

export default Routes;

// END OF FILE #################################################################
